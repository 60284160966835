body {
    overscroll-behavior: none;
}

.App {
    display: grid;
    grid-template-columns: 250px 1fr;
    grid-template-rows: auto 1fr;
    height: 100vh;
    max-height: 100vh;
}

.TopBar {
    grid-column: 1/3;
    grid-row: 1;
}

.Controls {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 16px;
    background-color: #dfdfdf;
    border-right: 1px solid #888888;
    overflow-y: auto;
}

.Spellbook {
    grid-column: 2;
    grid-row: 2;
    background-color: #f0f0f0;
    overflow-y: auto;
}

.Overlays {
    grid-column: 2;
    grid-row: 2;
}

@media print {
    .Controls {
        display: none;
    }

    .App {
        height: auto;
        max-height: none;
    }

    .Spellbook {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        overflow-y: auto;
    }
}
