.CardFront,
.DisabledCardFront {
    width: 2.5in;
    height: 3.5in;
    vertical-align: top;
    display: inline-flex;
    flex-direction: column;
    break-inside: avoid;

    margin: 8px;
    padding: 10px 10px 6px 10px;
    border-radius: 12px;

    font-family: "modesto-text", serif;
    font-style: normal;
    font-weight: 300;

    cursor: pointer;
    user-select: none;
}

.CardFront:hover {
    opacity: 0.9;
}

.DisabledCardFront {
    opacity: 0.5;
}

.DisabledCardFront:hover {
    opacity: 0.65;
}

@media print {
    .CardFront,
    .DisabledCardFront {
        -webkit-print-color-adjust: exact;
        margin: 2px !important;
    }

    .DisabledCardFront {
        display: none;
    }
}

.Title {
    padding: 2px 8px;
    background-color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 14px;
    line-height: 14px;
    font-family: "modesto-expanded", serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    text-align: center;
}

.Subtitle {
    padding: 2px;
    text-align: center;
    color: white;
    font-size: 8px;
    line-height: 8px;
}

.StatsTable {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2px;
    width: 100%;
    margin-bottom: 4px;
    border-collapse: collapse;
}

.StatCell {
    background-color: white;
    border: 0 solid transparent;
    background-clip: padding-box;
    padding: 2px;
}

.StatCellStretch {
    grid-column: span 2;
}

.CellContent {
    display: grid;
    grid-template-columns: 16px auto 16px;
    justify-items: center;
    align-items: center;
}

.StatsTitle {
    grid-row: 1;
    grid-column: 1/4;
    text-align: center;
    font-size: 10px;
    line-height: 10px;
    font-family: "modesto-expanded", serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
}

.StatsValue {
    grid-row: 2;
    grid-column: 1/4;
    text-align: center;
    font-size: 10px;
    line-height: 10px;
}

.StatsValue::first-letter {
    text-transform: capitalize;
    user-select: none;
}

.StatsBadge {
    grid-row: 1/3;
    grid-column: 3;
    display: flex;
}

.DetailsContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.DetailsContainer :first-child {
    padding-top: 8px;
}

.DetailsContainer :last-child {
    padding-bottom: 8px;
}

.DetailsBlock {
    padding: 3px 8px;
    background-color: white;
    font-size: 7.5px;
    line-height: 10px;
    text-align: justify;
}

.DetailsBlock :first-child, .DetailsBlock :last-child {
    padding-top: unset;
    padding-bottom: unset;
}

.DetailsBlock ul {
    margin: 0;
    list-style-type: square;
    list-style-position: outside;
    padding-inline-start: 12px;
}

.BoldDetail {
    font-weight: bold;
}

.ItalicDetail {
    font-style: italic;
}

.HigherLevels {
    padding: 2px;
    text-align: center;
    color: white;
    font-size: 8px;
    line-height: 8px;
}

.HigherLevels ~ .DetailsBlock {
    padding-top: 8px;
}

.CardFooter {
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
}

.Category {
    font-size: 11px;
    line-height: 11px;
    font-family: "modesto-expanded", serif;
    font-weight: 400;
    font-style: normal;
    /*text-transform: uppercase;*/
    text-align: center;
}
