.CardBack,
.DisabledCardBack {
    width: 2.5in;
    height: 3.5in;
    vertical-align: top;
    display: inline-flex;
    break-inside: avoid;

    border-radius: 12px;
    margin: 8px;
    padding: 10px;

    cursor: pointer;
    user-select: none;
}

.CardBack:hover {
    opacity: 0.9;
}

.DisabledCardBack {
    opacity: 0.5;
}

.DisabledCardBack:hover {
    opacity: 0.65;
}

.ArtBackground {
    position: relative;
    flex: 1;
    padding: 8px;
    background-color: floralwhite;
    border-radius: 8px;
}

.ArtInner {
    position: relative;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
    align-items: center;

    border: 4px solid;
    border-radius: 8px;
}

.Diamond1,
.Diamond2,
.Diamond3,
.Diamond4 {
    justify-self: stretch;
    align-self: stretch;
}

.Diamond1 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.Diamond2 {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.Diamond3 {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
}

.Diamond4 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.DiamondLine {
    stroke-width: 2.5;
}
.DiamondTextSm {
    font-family: 'IconFont', serif;
    text-anchor: middle;
    font-size: 7px;
}

.DiamondTextLg {
    font-family: 'IconFont', serif;
    text-anchor: middle;
    font-size: 12px;
}

.Icon {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
}

.TRCorner,
.BLCorner {
    margin: 0 4px;
    font-family: "modesto-condensed", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75in;
    line-height: 0.65in;
}

.TRCorner {
    grid-column: 2;
    grid-row: 1;
    justify-self: end;
    align-self: start;
}

.BLCorner {
    grid-column: 1;
    grid-row: 2;
    justify-self: start;
    align-self: end;
}

@media print {
    .CardBack,
    .DisabledCardBack {
        -webkit-print-color-adjust: exact;
        margin: 2px !important;
    }

    .DisabledCardBack {
        display: none;
    }

    .ArtBackground {
        background: white;
    }
}
